/*******************************
         Site Overrides
*******************************/

@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Thin.eot'); 
    src:url('../../../fonts/Cera-Pro-Thin.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Thin.woff') format('woff');
          font-weight: 200;
          font-style: normal;
}
@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Light.eot'); 
    src:url('../../../fonts/Cera-Pro-Light.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Light.woff') format('woff');
          font-weight: 300;
          font-style: normal;
}
@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Regular.eot'); 
    src:url('../../../fonts/Cera-Pro-Regular.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
}
@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Medium.eot'); 
    src:url('../../../fonts/Cera-Pro-Medium.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Medium.woff') format('woff');
          font-weight: 500;
          font-style: normal;
}
@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Bold.eot'); 
    src:url('../../../fonts/Cera-Pro-Bold.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
}
@font-face  {
    font-family: 'Cera-Pro';
    src:url('../../../fonts/Cera-Pro-Black.eot'); 
    src:url('../../../fonts/Cera-Pro-Black.eot?#') format('eot'),    
          url('../../../fonts/Cera-Pro-Black.woff') format('woff');
          font-weight: 900;
          font-style: normal;
}