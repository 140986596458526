.summary-visual-container {
    display: flex;
    flex-flow: row wrap;
    padding-top: 2em;
    justify-content: space-evenly;
    user-select: none;
}

.advice {
    margin-top: 3rem;
    text-align: left;
    /* stylelint-disable-next-line */
    p:not(:last-child) {
        font-weight: 500;
    }
}

.center {
    text-align: center;
}

.print-summary-block {
    margin-top: 3rem;

    /* possible false positive */
    /* stylelint-disable-next-line */
    page-break-before: always;
    text-align: center;
}

.react-print {
    display: none;

    .progress-ring-container {
        text-align: center;
        /* stylelint-disable-next-line */
        margin-top: 3rem;
    }
}
