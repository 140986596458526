.questionnaire {
    /* stylelint-disable selector-no-qualifying-type */
    /* stylelint-disable selector-max-specificity */
    /* stylelint-disable selector-max-compound-selectors */
    /* stylelint-disable selector-max-type */
    .ui.form .field > label.single-question {
        margin-bottom: 1.2rem;
        font-size: 1.2rem;
        text-align: center;
    }

    .ui.segments {
        border: 0;
        box-shadow: none;
    }

    .equal.width.fields.questions-container {
        justify-content: space-between;

        .field.option-button {
            max-width: 13em;
            /* stylelint-disable-next-line */
            @media (max-width: 767px) {
                max-width: 100%;
                margin: 1rem 0;
            }
        }
    }

    .ui.header.question-header {
        padding: 0.7em;
        margin-bottom: 3rem;
        color: $light-text-color;
        background: $secondary-color;
    }
    /* stylelint-enable selector-no-qualifying-type */
    /* stylelint-enable selector-max-specificity */
    /* stylelint-enable selector-max-compound-selectors */
    /* stylelint-enable selector-max-type */
}
