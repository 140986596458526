.smiley-button {
    padding: 2rem;

    .smiley-image {
        width: 8rem;
    }

    .smiley-label {
        width: 100%;
        margin-top: 1em;
        font-weight: 500;
        text-align: center;
        overflow-wrap: break-word;
    }
}
