@import "../../../../App.Common/styles/index";

.sra-summary-container {
    .summary-text-container {
        margin: 1em 5em;
        text-align: center;
    }

    .summary-paragraph {
        padding: 1em 0;
        margin: 0;
    }

    .no-results-text {
        padding-top: 3rem;
        text-align: center;
    }

    .sra-summary-team-text {
        margin-top: 4em;
        font-size: 1.1em;
    }

    .row.report-title-row {
        padding: 1em 0;
        background: $primary-color;
        border-bottom: 0;
    }

    .details-grid {
        .row {
            padding: 0.5em 0;
            border-bottom: 1px solid $border-color;
        }

        .primary-column {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-top: -0.5rem !important; /* To match Semantic UI styling */
            margin-bottom: -0.5rem !important; /* To match Semantic UI styling */
            font-weight: 500;
            color: $secondary-color;
            background-color: $light-grey-color;
            /* stylelint-disable-next-line */
            @media (max-width: 767px) {
                margin-bottom: 0.5rem !important; /* To match Semantic UI styling */
            }
        }
    }

    .row.no-flex {
        display: block;
    }

    .row.report-section-row {
        padding: 0.75em 0;
        margin-top: 1em;
        background: $secondary-color;
        /* stylelint-disable-next-line */
        .ui.header {
            color: $light-text-color;
        }
    }
}
