@media print {
    .ui.menu {
        display: none;
        margin-bottom: 0;
    }

    .ui.button.print-button {
        display: none;
    }

    .react-no-print {
        display: none;
    }

    .react-print {
        display: block;
    }

    .ui.grid {
        margin-left: 0;
    }
}

@page {
    size: auto;
    margin: 5mm;
}
